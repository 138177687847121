import React from 'react';
import moment from 'moment';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';

function ProgramListItem({ program, handleClickProgram }) {
  return (
    <div
      className="w-full flex flex-row justify-between items-end p-5 cursor-pointer hover:bg-slate-100"
      onClick={handleClickProgram}>
      <div className="h-full flex flex-row gap-x-5">
        <img
          className="h-32 w-32 object-cover shadow-md block rounded-md"
          src={program.image ? program.image.file : getImage('img-ph.png')}
          alt={program.image ? program.image.label : 'Non renseignée'}
        />
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-y-1">
            <div className="text-xl font-bold text-slate-700 ">{program.title}</div>
            <span className="font-medium text-slate-700">{program.author}</span>
          </div>
          <div className="flex flex-row gap-x-3">
            <Tag
              tag={{
                label: `${program.upcoming_event_nb} évènements à venir`,
                icon: 'event',
                bgColor: `${program.upcoming_event_nb ? 'bg-green-500' : 'bg-gray-500'}`
              }}
            />
            {program.properties.map((p) => (
              <Tag
                tag={{
                  label: p.label
                }}
                rounded
                key={p.id}
              />
            ))}
          </div>
        </div>
      </div>
      <p className="text-sm italic">{moment(program.date).format('DD/MM/YYYY')}</p>
    </div>
  );
}

export default ProgramListItem;
