import React from 'react';
import Data from '../../../../components/Display/Data';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';
import MultiLineData from '../../../../components/Display/MultiLineData';
import Section from '../../../../components/Display/Section';
import { getImage } from '../../../../utils/imageHelper';
import { DataView } from 'primereact/dataview';
import EventListItem from './EventListItem';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import DocumentItem from '../../common/document/DocumentItem';
import NewsListItem from '../news/NewsListItem';
import DataTag from '../../../../components/Display/DataTag';
import { getConstant } from '../../../../utils/constants';
import moment from 'moment';

function ProgramDetail({
  program,
  handleUpdateProgram,
  handleDuplicateEvent,
  handleDeleteEvent,
  handleCreateEvent,
  handleUpdateEvent,
  handleUpdateDistribution,
  handleClickResource,
  handleDelete,
  handleCreateFile,
  handleUpdateFile,
  handleDeleteFile,
  handleCreateNews,
  handleUpdateNews,
  handleDeleteNews
}) {
  const updateProgramActions = [
    {
      onClick: handleUpdateProgram,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const newEventActions = [
    {
      onClick: handleCreateEvent,
      label: 'Ajouter un évènement',
      icon: 'pi-plus'
    }
  ];

  const newNewsActions = [
    {
      onClick: handleCreateNews,
      label: 'Ajouter un article',
      icon: 'pi-plus'
    }
  ];

  const editionDistributionActions = [
    {
      onClick: handleUpdateDistribution,
      label: 'Modifier la distribution',
      icon: 'pi-pencil'
    }
  ];

  const newFileActions = [
    {
      onClick: handleCreateFile,
      label: 'Ajouter un fichier',
      icon: 'pi-plus'
    }
  ];

  const confirmDeletion = (program) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Programme ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(program)
    });
  };

  const confirmDocumentDeletion = (document) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteFile(document)
    });
  };

  const confirmNewsDeletion = (news) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet article ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteNews(news)
    });
  };

  const eventItemTemplate = (event) => {
    return (
      <EventListItem
        event={event}
        duplicateEvent={handleDuplicateEvent}
        deleteEvent={handleDeleteEvent}
        updateEvent={handleUpdateEvent}
      />
    );
  };

  const newsItemTemplate = (news) => {
    return (
      <NewsListItem news={news} deleteNews={confirmNewsDeletion} updateNews={handleUpdateNews} />
    );
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateProgramActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={program.title} large />
          <Data key="author" label={'Auteur'} value={program.author} large />
          <DataTag
            key="type"
            label="Type"
            tag={getConstant('banquetceleste.programTypes', program.type)}
            rounded
            large
          />
          <Data key="deezerId" label={'ID Deezer'} value={program.deezer_id} large />
        </div>
        <div className="flex flex-wrap gap-5">
          <Data
            key="date"
            label={'Date de publication'}
            value={moment(program.date).format('DD/MM/YYYY')}
            large
          />
          <MultipleDataTag
            key="properties"
            label={'Propriétés'}
            rounded
            tags={program.properties.map((property) => ({
              label: property.label
            }))}
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <MultipleDataTag
            key="resources"
            label={'Ressources'}
            tags={program.resources.map((resource) => ({
              label: resource.title,
              icon: getConstant('banquetceleste.resourceTypes', resource.type).icon,
              onClick: () => handleClickResource(resource)
            }))}
            rounded
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={program.description}
            large
            asHtml
          />
          <MultiLineData key="credits" label={'Crédits'} value={program.credits} large asHtml />
        </div>
      </Section>
      <Section label="Image principale">
        <img
          className="w-96"
          src={program.image ? program.image.file : getImage('img-ph.png')}
          alt={program.image ? program.image.label : 'Non renseignée'}
        />
      </Section>
      <Section label="Fichiers téléchargeables" actions={newFileActions}>
        {program.files?.length > 0 ? (
          <div className="flex flex-wrap gap-3">
            {program.files.map((file) => (
              <DocumentItem
                key={file.id}
                document={file}
                onUpdate={() => handleUpdateFile(file)}
                onDelete={() => confirmDocumentDeletion(file)}
              />
            ))}
          </div>
        ) : (
          <p className="italic text-slate-400 font-light">Non renseignés</p>
        )}
      </Section>
      <Section label="Distribution" actions={editionDistributionActions}>
        {program.distributions?.length > 0 ? (
          <div className="flex flex-wrap gap-3">
            {program.distributions.map((distrib, id) => (
              <Data label={distrib.role} key={id} value={distrib.alias} large />
            ))}
          </div>
        ) : (
          <p className="italic text-slate-400 font-light">Non renseignée</p>
        )}
      </Section>
      <Section label="Évènements" actions={newEventActions}>
        <DataView
          value={program.events}
          itemTemplate={eventItemTemplate}
          emptyMessage="Aucun évènement à afficher"
        />
      </Section>

      <Section label="Articles de presse" actions={newNewsActions}>
        <DataView
          value={program.news}
          itemTemplate={newsItemTemplate}
          emptyMessage="Aucun article à afficher"
        />
      </Section>

      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={'Supprimer le programme'}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(program)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default ProgramDetail;
