import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RichTextEditor({ value, onChange, height }) {
  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    }
  };
  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      className={`${height || 'h-96'} mb-12`}
      modules={modules}
    />
  );
}

export default RichTextEditor;
