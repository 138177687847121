import React from 'react';
import Data from '../../../../components/Display/Data';
import MultiLineData from '../../../../components/Display/MultiLineData';
import Section from '../../../../components/Display/Section';
import { getImage } from '../../../../utils/imageHelper';
import moment from 'moment';
import { Button } from 'primereact/button';
import { confirmDialog } from 'primereact/confirmdialog';
import ImageItem from '../../common/image/ImageItem';
import { getConstant } from '../../../../utils/constants';
import DataTag from '../../../../components/Display/DataTag';
import NewsListItem from '../news/NewsListItem';
import { DataView } from 'primereact/dataview';

function ResourceDetail({
  resource,
  handleUpdateResource,
  handleUpdateDistribution,
  handleDelete,
  handleCreateImage,
  handleUpdateImage,
  handleDeleteImage,
  handleCreateNews,
  handleUpdateNews,
  handleDeleteNews
}) {
  const updateResourceActions = [
    {
      onClick: handleUpdateResource,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const newDistribActions = [
    {
      onClick: handleUpdateDistribution,
      label: 'Modifier la distribution',
      icon: 'pi-pencil'
    }
  ];

  const newNewsActions = [
    {
      onClick: handleCreateNews,
      label: 'Ajouter un article',
      icon: 'pi-plus'
    }
  ];

  const newImageActions = [
    {
      onClick: handleCreateImage,
      label: 'Ajouter une image',
      icon: 'pi-plus'
    }
  ];

  const confirmDeletion = (resource) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Disque ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(resource)
    });
  };

  const confirmImageDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteImage(image)
    });
  };

  const confirmNewsDeletion = (news) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet article ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteNews(news)
    });
  };

  const newsItemTemplate = (news) => {
    return (
      <NewsListItem news={news} deleteNews={confirmNewsDeletion} updateNews={handleUpdateNews} />
    );
  };

  return (
    <div className={`h-full w-full flex-col flex gap-5`}>
      <Section label="Informations Générales" actions={updateResourceActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={resource.title} large />
          <DataTag
            key="type"
            label={'Type'}
            tag={getConstant('banquetceleste.resourceTypes', resource.type)}
            large
            rounded
          />
          <Data key="author" label={'Auteur'} value={resource.author} large />
          <Data key="serie" label={'Série'} value={resource.serie} large />

          <Data
            key="date"
            label={'Date'}
            value={moment(resource.date).format('DD/MM/YYYY')}
            large
          />
          <Data
            key="catalog_number"
            label={'Numéro du Catalogue'}
            value={resource.catalog_number}
            large
          />
          <Data key="label_name" label={'Nom du label'} value={resource.label_name} large />
          <Data key="label_url" label={'Site web du label'} value={resource.label_url} large />
          <Data key="deezer_id" label={'Id Deezer'} value={resource.deezer_id} large />
          <Data key="video_url" label={'URL Youtube'} value={resource.video_url} large />
        </div>
        <div className="flex flex-wrap gap-5">
          <MultiLineData
            key="description"
            label={'Description'}
            value={resource.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Image principale">
        <img
          className="w-96"
          src={resource.image ? resource.image.file : getImage('img-ph.png')}
          alt={resource.image ? resource.image.label : 'Non renseignée'}
        />
      </Section>
      <Section label="Autres images" actions={newImageActions}>
        <div className="flex flex-wrap gap-3">
          {!resource.images.length && (
            <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
          )}
          {resource.images.map((img, index) => (
            <ImageItem
              key={index}
              image={img}
              alt={`Photo de ${resource.name} n°${index}`}
              imageClassName="h-60"
              updateImage={() => handleUpdateImage(img)}
              deleteImage={() => confirmImageDeletion(img)}
            />
          ))}
        </div>
      </Section>
      <Section label="Distribution" actions={newDistribActions}>
        {resource?.distributions?.length > 0 ? (
          <div className="flex flex-wrap gap-3">
            {resource.distributions.map((distrib, id) => (
              <Data label={distrib.role} key={id} value={distrib.alias} large />
            ))}
          </div>
        ) : (
          <p className="italic text-slate-400 font-light">Non renseignée</p>
        )}
      </Section>

      <Section label="Articles de presse" actions={newNewsActions}>
        <DataView
          value={resource.news}
          itemTemplate={newsItemTemplate}
          emptyMessage="Aucun article à afficher"
        />
      </Section>

      <Section label="Autres actions">
        <div className="flex flex-row">
          <Button
            key={'delete'}
            label={'Supprimer la ressource'}
            className="w-54"
            outlined
            onClick={() => confirmDeletion(resource)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default ResourceDetail;
